<template>
  <div class="input-container p-relative">
    <div
      class="input text-base cursor-pointer d-flex jc-space-between ai-center bg-form"
      @click="openProducts = true"
      :class="{ 'br-bottom-left-0 br-bottom-right-0' : openProducts }"
    >
      {{ productName ? productName : 'Select SL points' }}
      <Icon name="arrow-down-white" :class="openProducts ? 'arrow-up-white' : 'arrow-down-white'" class="mr-12" color="#60BCE3" />
    </div>
    <div
      class="p-absolute z-101 top-49px right-0px left-0px py-12 px-16 bg-form bc-link-border-lighter b-all br-bottom-left-5 br-bottom-right-5"
      v-show="openProducts"
    >
      <div
        v-for="product in availableProducts"
        :key="product.points"
        class="text-base pt-4"
      >
        <a
          class="d-block color-link_hover px-16"
          @click.prevent="selectProduct(product)"
        >{{ product.name }}</a>
      </div>
    </div>
    <div
      class="p-fixed z-100 top-0 left-0 right-0 bottom-0"
      v-show="openProducts"
      @click="openProducts = false"
    ></div>
  </div>
  <div class="d-flex jc-space-between ai-center w-100 mt-24">
    <div>
      <span class="text-h6">Total: &nbsp;</span>
      <span v-if="productPriceOld" class="text-h4 color-text-lighter"><span class="decor-through">${{ productPriceOld }}</span>&nbsp;</span>
      <span class="text-h4 color-link">${{ productPrice }}</span>
    </div>
    <div class="d-flex justify-end">
      <button
        class="bttn-primary w-260px"
        :disabled="!checkoutUrl.length"
        @click="toCheckout"
      >
        Buy
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';

export default {
  name: 'PlanMaltegoSlp',
  props: ['loadedProducts', 'slpPrice'],
  components: {
    Icon
  },
  data () {
    return {
      openProducts: false,
      productName: '',
      productPoints: null,
      productPrice: 0,
      checkoutUrl: ''
    };
  },
  computed: {
    availableProducts () {
      const unsubProducts = this.loadedProducts.filter(p => p.subscription === false);
      return unsubProducts.sort((a, b) => a.points - b.points);
    },
    productPriceOld () {
      const priceOld = (this.slpPrice / 100 * this.productPoints).toFixed();
      console.log(priceOld, this.productPrice);

      if ((priceOld * 1) === (this.productPrice * 1)) return null;
      else return priceOld;
    }
  },

  methods: {
    selectProduct (product) {
      this.productName = product.name;
      this.productPoints = product.points;
      this.productPrice = product.price;
      this.checkoutUrl = product.checkout_url;
      this.openProducts = false;
      console.log(product);
    },
    toCheckout () {
      window.open(this.checkoutUrl, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
  .primary-btn {
    width: 220px;
  }
</style>
