<template>
  <div v-show="loading">
    <div class="modal-background"></div>
     <div class="modal-wrap">
      <div class="saving-modal d-flex flex-column align-center">
        <div>
          <img src="@/assets/icons/searching.gif" alt="" class="h-78">
        </div>
        <span>Loading...</span>
      </div>
    </div>
  </div>
  <div class="buy-container" v-if="!thanks">
    <div class="text-h4 mb-24">Buy SL points</div>
    <div class="slpoints-block">
      <h4 class="text-h4 mb-16">SL points (min - 100 slp, max - 10 000 slp)</h4>
      <div class="d-flex justify-between">
        <div class="d-flex flex-column text-base color-text-darker">
        <span class="pb-12">From 500 slp - 5% discount</span>
        <span class="pb-16">From 1 000 slp - 10% discount</span>
        </div>
        <div class="d-flex flex-column text-base color-text-darker">
        <span class="pb-12">From 5 000 slp - 15% discount</span>
        <span class="pb-16">10 000 slp - 20% discount</span>
        </div>
      </div>
      <Feature visible="VUE_APP_NSA_1558">
        <template v-if="maltegoSubscription">
          <PlanMaltegoSlp
            :loadedProducts="loadedProducts"
            :slpPrice="slpPrice"
          />
        </template>
        <template v-else>
          <div class="input-container">
            <input
            type="number"
            ref="slpInput"
            max="10000"
            min="100"
            class="input bg-form"
            placeholder="slp"
            @input="inputCorrection"
            @keydown.enter="buySlp"
            v-model.number="slpToBuy">
            <div class="notification-panel d-flex justify-between" v-if="slpToBuy !== multipledSlp && slpToBuy !== null">
              <span class="text-base">You can only top up your balance in an amount that is a multiple of 100.</span>
              <button class="text-link" @click="multiplySlp">up to<span>&nbsp;{{multipledSlp}}&nbsp;</span>slp</button>
            </div>
          </div>
          <div class="total-container d-flex align-center justify-between w-100 mt-24">
            <div>
              <span class="text-h6">Total: &nbsp;</span>
              <span class="text-h6" :class="{'decor-through color-text-lighter': showDiscont, 'color-link': !showDiscont}">${{(slpToBuy / 100 * slpPrice).toFixed()}}</span>
              <span class="text-h6 color-link" v-if="showDiscont">&nbsp;${{discounted}}</span>
            </div>
            <div class="d-flex justify-end"><button class="bttn-primary w-260px" :disabled="disableBuyBtn" @click="buySlp">Buy</button></div>
          </div>
        </template>
      </Feature>
      <Feature visible="!VUE_APP_NSA_1558">
        <div class="input-container">
          <input
          type="number"
          ref="slpInput"
          max="10000"
          min="100"
          class="slp-input segoe-font"
          placeholder="slp"
          @input="inputCorrection"
          @keydown.enter="buySlp"
          v-model.number="slpToBuy">
          <div class="notification-panel d-flex justify-between" v-if="slpToBuy !== multipledSlp && slpToBuy !== null">
            <span>You can only top up your balance in an amount that is a multiple of 100.</span>
            <button class="color-theme fw-600 segoe-font" @click="multiplySlp">up to<span>&nbsp;{{multipledSlp}}&nbsp;</span>slp</button>
          </div>
        </div>
        <div class="total-container d-flex align-center justify-between w-100 mt-24">
          <div>
            <span class="typography-theme segoe-font colored let-space-04">Total: &nbsp;</span>
            <span class="full-price" :class="{'max-price': showDiscont}">${{(slpToBuy / 100 * slpPrice).toFixed()}}</span>
            <span class="disconted-price" :class="{'min-price': showDiscont}" v-if="showDiscont">&nbsp;${{discounted}}</span>
          </div>
          <div class="d-flex justify-end"><button class="primary-btn" :disabled="disableBuyBtn" @click="buySlp">Buy</button></div>
        </div>
      </Feature>
  </div>
  <h3 class="text-h4 mb-24 mt-32">Plan</h3>
  <div class="plan-item mb-48 text-base color-text-darker" v-if="subscription">
    <div class="d-flex justify-between align-center mb-16" v-if="subscriptionName">
      <h4 class="text-h4">{{ subscriptionName }}</h4>
      <span class="color-theme" v-if="!trial">$33 / month</span>
    </div>
    <div class="mb-12" v-if="subscriptionExpires">
      <span>Expired date: {{ subscriptionExpires }} &nbsp;&nbsp;</span>
      <span class="color-theme" v-if="expiredByDate">active</span>
      <span class="cancel-color" v-else>expired</span>
    </div>
    <div class="mb-12">
      <span>Monthly package: {{ totalPoints }} slp &nbsp;&nbsp;</span>
      <span class="color-theme" v-if="balance > 0">active</span>
      <span class="color-theme" v-else>spent</span>
    </div>
    <div class="d-flex justify-between align-center">
      <div>
        <span>Auto renewal subscription: {{ autoRenew ? 'on' : 'off' }} &nbsp;&nbsp;</span>
       <span class="color-theme" v-if="autoRenew">Active</span>
       <span class="cancel-color" v-else>Cancel</span>
      </div>
      <router-link to="/paywall" class="edit-btn">Edit Plan</router-link>
    </div>
  </div>
  <!-- <div class="plan-item mb-48" v-if="subscription">
    <div class="d-flex justify-between align-center mb-16" v-if="subscriptionName">
      <h3>{{ subscriptionName }}</h3>
      <span class="typography-theme color-theme" v-if="!trial">$99 / month</span>
    </div>
    <div class="typography-7 color-1 mb-12" v-if="subscriptionExpires">
      <span class="colored segoe-font">Expired date: <span class="typography-text">{{ subscriptionExpires }} &nbsp;&nbsp;</span></span>
      <span class="color-theme" v-if="expiredByDate">active</span>
      <span class="cancel-color" v-else>expired</span>
    </div>
    <div class="typography-7 color-1 mb-12">
      <span class="colored segoe-font">Daily limit: <span class="typography-text">1 000 000 slp&nbsp;&nbsp;</span><Icon name="arrow-down-white" :color="'#60BCE3'"/></span>
    </div>
    <div class="typography-7 color-1 mb-12">
      <span class="colored segoe-font">API Key: <span class="typography-text">staff-9NEWSrpEbMt1ayPLDQqsgvybbl&nbsp;&nbsp;</span><Icon name="arrow-down-white" :color="'#60BCE3'"/></span>
    </div>
  </div> -->
  <div class="payment-block">
    <h3 class="text-h4 mb-24 mt-32">Payment methods</h3>
    <div class="payment-cart-block plan-item mb-48 d-flex align-center justify-between">
      <Icon name='visa' />
      <span class="cart-number text-base color-text-darker">**** **** **** {{accountSubscription.card_last4}}</span>
      <span class="account-name text-base color-text-darker">{{accountState.name}}</span>
      <span class="text-base color-text-darker">Expired date: <span class="typography-text">{{ accountSubscription.card_expiration }} &nbsp;&nbsp;</span></span>
      <div><a href="https://cc.payproglobal.com/Customer/Account/Login" target="_blank"><button class="text-base color-link">Edit</button></a></div>
    </div>
   </div>
  </div>
    <div v-else>
      <div class="gratitude d-flex flex-column align-center" v-if="!buyError">
        <div class="img-container">
          <img :src="require('../assets/images/ty.png')" alt="Thank You">
        </div>
        <div class="mt-32">
          <h3 class="typography-1 fw-600 segoe-font color-black text-center">Thank You!</h3>
          <p class="typography-11 custom-font">Payment was successful</p>
        </div>
        <div class="mt-32">
          <button class="primary-btn" @click="$router.push('/')">Next</button>
        </div>
      </div>
      <div class="gratitude d-flex flex-column align-center" v-else>
      <div class="mt-32">
          <h3 class="typography-1 fw-600 segoe-font color-black text-center">Error!</h3>
          <p class="typography-11 custom-font">Payment wasn’t successful</p>
        </div>
        <div class="mt-32">
          <button class="primary-btn" @click="thanks = false">Next</button>
        </div>
      </div>
  </div>
  <SendModal v-if="openModal" @closeModal="openModal = false" :data="modalWindowData" />
</template>

<script>
import Feature from '@/components/app/Feature';
import PlanMaltegoSlp from '@/components/PlanMaltegoSlp';
import SendModal from '@/components/app/SendModal';
import Icon from '@/components/app/Icon';
import { mapGetters } from 'vuex';

export default {
  name: 'PlanComponent',
  components: {
    Feature,
    PlanMaltegoSlp,
    SendModal,
    Icon
  },
  data () {
    return {
      sublist1: false,
      sublist2: false,
      openModal: false,
      modalWindowData: {
        title: 'Our managers will contact you',
        options: ['Payment'],
        placeholder: '',
        text: ''
      },
      slpToBuy: null,
      debounce: null,
      takenPrice: null,
      loading: false,
      thanks: false,
      buyError: false
    };
  },
  computed: {
    ...mapGetters(['accountState', 'loadedProducts']),
    accountSubscription () {
      if (this.accountState?.subscription?.card_expiration && this.accountState?.subscription?.card_last4) {
        return this.accountState?.subscription;
      }
      return false;
    },
    disableBuyBtn () {
      if (this.slpToBuy !== this.multipledSlp || this.slpToBuy < 100) {
        return true;
      }
      return false;
    },
    showDiscont () {
      if (this.takenPrice?.discount) {
        return this.takenPrice.discount !== 0;
      }
      return false;
    },
    slpPrice () {
      return +this.loadedProducts.find(item => item.code === '100_slp').price;
    },
    discounted () {
      if (this.takenPrice) {
        return this.takenPrice.price;
      }
      return 0;
    },
    multipledSlp () {
      return Math.ceil(this.slpToBuy / 100) * 100;
    },
    account () {
      return this.$store.getters.accountState;
    },
    balance () {
      return this.account.balance;
    },
    subscriptionName () {
      return this.account.subscription?.name;
    },
    subscription () {
      return this.account.subscription;
    },
    subscriptionExpires () {
      const expires = this.account.subscription?.expires;
      if (expires) {
        const date = new Date(expires * 1000);
        const day = '0' + date.getDate();
        const month = '0' + (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day.substr(-2)}.${month.substr(-2)}.${year}`;
      }
      return '';
    },
    autoRenew () {
      return this.account.subscription?.auto_renew;
    },
    totalPoints () {
      return this.account.subscription?.points.toLocaleString('ru-RU');
    },
    expiredByDate () {
      return this.account.subscription && this.account.subscription.active;
    },
    trial () {
      return this.account.subscription?.trial;
    },
    maltegoSubscription () {
      return this.account.subscription.maltego && this.account.subscription.trial;
    }
  },
  methods: {
    async buySlp () {
      this.loading = true;
      const payload = {
        method: 'POST',
        url: '/api/v3/ppg/packages/order',
        body: {
          amount: this.slpToBuy
        }
      };
      try {
        await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        await this.$store.dispatch('checkAccount');
        this.loading = false;
        this.thanks = true;
      } catch (e) {
        this.thanks = true;
        this.loading = false;
        this.buyError = true;
      }
    },
    multiplySlp () {
      this.debounceLeading();
      this.slpToBuy = this.multipledSlp;
    },
    inputCorrection () {
      this.debounceLeading();
      const maxValue = 5;
      const maxSlp = 10000;
      if (this.slpToBuy.length > maxValue) {
        this.slpToBuy = this.slpToBuy.slice(0, maxValue);
      }
      if (this.slpToBuy > maxSlp) {
        this.slpToBuy = maxSlp;
      }
    },
    debounceLeading () {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const payload = {
          method: 'GET',
          url: `/api/v3/ppg/packages/price/${this.slpToBuy}`
        };
        try {
          const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
          this.takenPrice = response.data;
        } catch (e) {
          this.takenPrice = {};
        }
      }, 1000);
    },
    openSublist1 () {
      this.sublist1 = !this.sublist1;
    },
    openSublist2 () {
      this.sublist2 = !this.sublist2;
    },
    openModalWindow (slp) {
      this.openModal = true;
      this.modalWindowData.text = this.modalWindowData.placeholder = `I want to buy ${slp} slp`;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-btn {
  color: #60BCE3;
}

.payment-cart-block {
  padding: 32px 24px;
}

.full-price {
    color: #60BCE3;
  font-weight: 600;
  font-size: 18px;
}

.max-price {
  text-decoration: line-through;
  font-weight: 500;
  font-size: 16px;
  color: #A0B2BE;
}

.min-price {
  color: #60BCE3;
  font-weight: 600;
  font-size: 18px;
}

.buy-container {
  padding: 24px;
}

.segoe-font {
  font-family: 'Segoe UI', sans-serif;
}

.colored {
  color: #29292B;
}

.notification-panel {
  padding: 16px;
  background: #EFF4F7;
}

.slp-input {
  padding: 12px 16px 12px 16px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #8D8D8E;
  border-radius: 5px 5px 0 0;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.typography-text {
  color: #8D8D8E;
  font-weight: 400;
}

.slpoints-block {
  padding: 24px;
  .d-flex {
    & div {
      flex-basis: 50%;
    }
  }
}

.primary-btn {
  width: 220px;
}

.bold {
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.06em;
}

.gratitude {
  margin: 150px auto;
  width: 30%;
}

.custom-font {
  font-family: 'Open Sans', sans-serif;
}
</style>
