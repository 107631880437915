<template>
  <h1 class="typography-1 color-1 mb-24" v-if="subscription">Plan</h1>
  <div class="plan-item mb-48" v-if="subscription">
    <div class="d-flex justify-between align-center mb-16" v-if="subscriptionName">
      <h3>{{ subscriptionName }}</h3>
      <span class="typography-theme color-theme" v-if="!trial">$33 / month</span>
    </div>
    <div class="typography-4 color-1 mb-12" v-if="subscriptionExpires">
      <span>Expired date: {{ subscriptionExpires }} &nbsp;&nbsp;</span>
      <span class="color-theme" v-if="expiredByDate">active</span>
      <span class="cancel-color" v-else>expired</span>
    </div>
    <div class="typography-4 color-1 mb-12">
      <span>Monthly package: {{ totalPoints }} slp &nbsp;&nbsp;</span>
      <span class="color-theme" v-if="balance > 0">active</span>
      <span class="color-theme" v-else>spent</span>
    </div>
    <div class="d-flex justify-between align-center typography-4 color-1">
      <div>
        <span>Auto renewal subscription: {{ autoRenew ? 'on' : 'off' }} &nbsp;&nbsp;</span>
<!--        <span class="cancel-color">Cancel</span>-->
      </div>
      <router-link to="/paywall" class="edit-btn">Edit Plan</router-link>
    </div>
  </div>
<!--  <div class="plan-item mb-48">-->
<!--    <h3 class="mb-16">Social links Pro</h3>-->
<!--    <div class="typography-4 color-1 mb-12">-->
<!--      <span>Expired date: 21.02.2022 &nbsp;&nbsp;</span>-->
<!--      <span class="color-theme">active</span>-->
<!--    </div>-->
<!--    <div class="d-flex typography-4 color-1 mb-12">-->
<!--      <button @click="openSublist1">Daily limit: 1 000 000 slp &nbsp;&nbsp; <Icon name="arrow-down-white" color="#60BCE3" /></button>-->
<!--    </div>-->
<!--    <div class="plan-item_sublist mb-12" v-if="sublist1">-->
<!--      <span class="d-block mb-12">Limits</span>-->
<!--      <ul class="plan-item_sublist-info">-->
<!--        <li>IP vs Phone: 10 000 </li>-->
<!--        <li>IP vs Email: 10 000 </li>-->
<!--        <li>Azure face search: 10 000 </li>-->
<!--        <li class="mb-12">Domain emails: 10 000 </li>-->

<!--        <li>Facebook: 1 000 000 </li>-->
<!--        <li>Facebook group members: 10 000 </li>-->
<!--        <li class="mb-12">Facebook search profile detailed: 10 000 </li>-->

<!--        <li>Sldb: 10 000 </li>-->
<!--        <li>Sldb2: 10 000 </li>-->
<!--        <li class="mb-12">Sldbl: 10 000 </li>-->

<!--        <li>Bloxy: 30 000 </li>-->
<!--        <li>Social mapper: 10 000 </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div class="d-flex typography-4 color-1">-->
<!--      <button @click="openSublist2">API Key: staff-9NEWSrpEbMt1ayPLDQqsgvybbl &nbsp;&nbsp;<Icon name="arrow-down-white" color="#60BCE3" /></button>-->
<!--    </div>-->
<!--    <div class="plan-item_sublist mb-12" v-if="sublist2">-->
<!--      <span class="d-block mb-12">Limits</span>-->
<!--      <ul class="plan-item_sublist-info">-->
<!--        <li>IP vs Phone: 10 000 </li>-->
<!--        <li>IP vs Email: 10 000 </li>-->
<!--        <li>Azure face search: 10 000 </li>-->
<!--        <li class="mb-12">Domain emails: 10 000 </li>-->

<!--        <li>Facebook: 1 000 000 </li>-->
<!--        <li>Facebook group members: 10 000 </li>-->
<!--        <li class="mb-12">Facebook search profile detailed: 10 000 </li>-->

<!--        <li>Sldb: 10 000 </li>-->
<!--        <li>Sldb2: 10 000 </li>-->
<!--        <li class="mb-12">Sldbl: 10 000 </li>-->

<!--        <li>Bloxy: 30 000 </li>-->
<!--        <li>Social mapper: 10 000 </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
  <h2 class="typography-1 color-1 mb-24 bold">Buy SL points</h2>
  <div class="slpoints-block">
    <div class="slpoints-item typography-theme">
      <span>100 slp</span>
      <div class="d-flex align-center">
        <span class="color-theme mr-32">$10</span>
        <button id="buy-100-slp" class="primary-btn" @click="openModalWindow(100)">Buy</button>
      </div>
    </div>
    <div class="slpoints-item typography-theme">
      <span>500 slp</span>
      <div class="d-flex align-center">
        <span class="color-theme mr-32">$40</span>
        <button id="buy-500-slp" class="primary-btn" @click="openModalWindow(500)">Buy</button>
      </div>
    </div>
    <div class="slpoints-item typography-theme">
      <span>1000 slp</span>
      <div class="d-flex align-center">
        <span class="color-theme mr-32">$70</span>
        <button id="buy-1000-slp" class="primary-btn" @click="openModalWindow(1000)">Buy</button>
      </div>
    </div>
  </div>
  <SendModal v-if="openModal" @closeModal="openModal = false" :data="modalWindowData" />
</template>

<script>
import SendModal from './app/SendModal';

export default {
  name: 'PlanComponent',
  components: {
    SendModal
  },
  data () {
    return {
      sublist1: false,
      sublist2: false,
      openModal: false,
      modalWindowData: {
        title: 'Our managers will contact you',
        options: ['Payment'],
        placeholder: '',
        text: ''
      }
    };
  },
  computed: {
    account () {
      return this.$store.getters.accountState;
    },
    balance () {
      return this.account.balance;
    },
    subscriptionName () {
      return this.account.subscription?.name;
    },
    subscription () {
      return this.account.subscription;
    },
    subscriptionExpires () {
      const expires = this.account.subscription?.expires;
      if (expires) {
        const date = new Date(expires * 1000);
        const day = '0' + date.getDate();
        const month = '0' + (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day.substr(-2)}.${month.substr(-2)}.${year}`;
      }
      return '';
    },
    autoRenew () {
      return this.account.subscription?.auto_renew;
    },
    totalPoints () {
      return this.account.subscription?.points.toLocaleString('ru-RU');
    },
    expiredByDate () {
      return this.account.subscription && this.account.subscription.active;
    },
    trial () {
      return this.account.subscription?.trial;
    }
  },
  methods: {
    openSublist1 () {
      this.sublist1 = !this.sublist1;
    },
    openSublist2 () {
      this.sublist2 = !this.sublist2;
    },
    openModalWindow (slp) {
      this.openModal = true;
      this.modalWindowData.text = this.modalWindowData.placeholder = `I want to buy ${slp} slp`;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-btn {
  color: #60BCE3;
}

.primary-btn {
  width: 220px;
}

.bold {
  font-weight: 600 !important;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.06em;
}
</style>
